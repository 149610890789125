import { BaseDto } from "@/shared/dtos/base-dto";
import { tipo_de_dato } from "./tipo_de_dato";

export class columna extends BaseDto {
  public nombre!: string;
  public data_bilding!: string;
  public orden!: number;
  public id_tipo_dato!: number;
  public id_pantalla_lista!: number;
  public tipo_de_dato!: tipo_de_dato;
  public visible!: boolean;
}
